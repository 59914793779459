import React from "react";

const Donate = () => {
  const handleDonateClick = () => {
    window.location.href = "https://www.paypal.com/donate/?hosted_button_id=CZNVQBN842DTN";
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gray-50">
      <h1 className="text-3xl font-bold text-blue-600 mb-4">Support Our Cause</h1>
      <p className="text-lg text-gray-700 mb-6">
        Your generous donation helps us continue making a difference. Thank you for your support!
      </p>
      <button
        onClick={handleDonateClick}
        className="px-6 py-3 bg-blue-600 text-white font-bold rounded hover:bg-blue-700 transition"
      >
        Donate Now
      </button>
    </div>
  );
};

export default Donate;