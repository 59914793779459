// src/pages/ThankYou.js
import React from 'react';

const ThankYou = () => {
  
  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <h1 className="text-3xl font-bold text-green-600 mb-4">Thank You for Your Donation!</h1>
      <p className="text-lg text-gray-700">
        Your generous contribution helps us make a difference in the lives of many. Thank you for your support!
      </p>
    </div>
  );
};

export default ThankYou;